// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-burden-of-oic-index-js": () => import("./../../../src/pages/burden-of-oic/index.js" /* webpackChunkName: "component---src-pages-burden-of-oic-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-copaysavingsprogram-index-js": () => import("./../../../src/pages/copaysavingsprogram/index.js" /* webpackChunkName: "component---src-pages-copaysavingsprogram-index-js" */),
  "component---src-pages-copaysavingsprogram-smsterms-js": () => import("./../../../src/pages/copaysavingsprogram/smsterms.js" /* webpackChunkName: "component---src-pages-copaysavingsprogram-smsterms-js" */),
  "component---src-pages-dosing-index-js": () => import("./../../../src/pages/dosing/index.js" /* webpackChunkName: "component---src-pages-dosing-index-js" */),
  "component---src-pages-hcp-burden-of-oic-index-js": () => import("./../../../src/pages/hcp/burden-of-oic/index.js" /* webpackChunkName: "component---src-pages-hcp-burden-of-oic-index-js" */),
  "component---src-pages-hcp-contact-index-js": () => import("./../../../src/pages/hcp/contact/index.js" /* webpackChunkName: "component---src-pages-hcp-contact-index-js" */),
  "component---src-pages-hcp-dosing-index-js": () => import("./../../../src/pages/hcp/dosing/index.js" /* webpackChunkName: "component---src-pages-hcp-dosing-index-js" */),
  "component---src-pages-hcp-efficacy-index-js": () => import("./../../../src/pages/hcp/efficacy/index.js" /* webpackChunkName: "component---src-pages-hcp-efficacy-index-js" */),
  "component---src-pages-hcp-index-js": () => import("./../../../src/pages/hcp/index.js" /* webpackChunkName: "component---src-pages-hcp-index-js" */),
  "component---src-pages-hcp-privacy-policy-index-js": () => import("./../../../src/pages/hcp/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-hcp-privacy-policy-index-js" */),
  "component---src-pages-hcp-safety-index-js": () => import("./../../../src/pages/hcp/safety/index.js" /* webpackChunkName: "component---src-pages-hcp-safety-index-js" */),
  "component---src-pages-hcp-savings-index-js": () => import("./../../../src/pages/hcp/savings/index.js" /* webpackChunkName: "component---src-pages-hcp-savings-index-js" */),
  "component---src-pages-hcp-savings-print-js": () => import("./../../../src/pages/hcp/savings/print.js" /* webpackChunkName: "component---src-pages-hcp-savings-print-js" */),
  "component---src-pages-hcp-sitemap-index-js": () => import("./../../../src/pages/hcp/sitemap/index.js" /* webpackChunkName: "component---src-pages-hcp-sitemap-index-js" */),
  "component---src-pages-hcp-terms-of-use-index-js": () => import("./../../../src/pages/hcp/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-hcp-terms-of-use-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-savings-index-js": () => import("./../../../src/pages/savings/index.js" /* webpackChunkName: "component---src-pages-savings-index-js" */),
  "component---src-pages-savings-print-js": () => import("./../../../src/pages/savings/print.js" /* webpackChunkName: "component---src-pages-savings-print-js" */),
  "component---src-pages-sitemap-index-js": () => import("./../../../src/pages/sitemap/index.js" /* webpackChunkName: "component---src-pages-sitemap-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */)
}

