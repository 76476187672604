import React, {useState, useEffect} from "react";


const defaultState = {
  setHCPViewed: () => {},
  HCPViewed: false,
  setNDCViewed: () => {},
  NDCViewed: false
}

const NavContext = React.createContext(defaultState);
const NDCContext = React.createContext(defaultState);

export const NavProvider = props => {
  
  const [ HCPViewed, setHCPViewed ] = useState(null);
  const [ NDCViewed, setNDCViewed ] = useState(null);

  return (
    <NavContext.Provider
      value={{
        HCPViewed,
        setHCPViewed,
        NDCViewed,
        setNDCViewed
      }}
    >
      {props.children}
    </NavContext.Provider>
  )
};

export const NavConsumer = NavContext.Consumer;

export default NavContext;